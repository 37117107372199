import React from 'react';

function BoxAbout (props) {
    return (  
        <div className='s-box'>
            <div className='s-b-text3'>
            <div className='s-b-img'>
                <img src={props.image} alt={props.alt}></img>
            </div>
            
            <h1 className='box3-header'>How it works</h1>
                <p>Whatever care your loved one needs, we find a bespoke solution that’s right for you. We know care needs can change over time, so we’re always ready to adapt.</p>
            </div>

        </div>
    );
}
 
export default BoxAbout;