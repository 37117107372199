import React from 'react';

function Box3 (props) {
    return (  
        <div className='s-box'>
            <div className='s-b-text3'>
            <h1 className='box3-header'>Home Care</h1>
                <p className='box3-sub-heading'>Home help and house keeping</p>
                <p className='details'>You will get Care Professionals to support you with household tasks. 
                    Our Care Professional will try to understand how you like things done in your house.  </p>
                <p className='box3-sub-heading'>Companionship</p>
                <p className='details'>We will ensure that you are in the company of a matched Care Professional whom you will enjoy spending time with.
                They will help you to build confidence venturing outdoors and enjoying a better social life.</p>
                
            </div>

        </div>
    );
}
 
export default Box3;