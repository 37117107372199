import React from 'react';
function Header () {
    return (  
        <div id='main'>
            <div className='name'>
                <h1><span></span></h1>
                <h1>Our Home Care Services</h1>
                <p className='details'>We can help you make an informed, compassionate choice 
                    for your loved one, with bespoke packages to suit their domiciliary 
                    care needs.</p>
            </div>
        </div>
    );
}
 
export default Header;