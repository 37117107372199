import React from 'react';
import Box3 from './Box3';
import BoxAbout from './Box-About';
import image2 from '../images/carers.png';

function About () {
    return (  
        <div id='about'>
            <div className='s-heading'>
            <div className='s-heading'>
        <h1>About us</h1>
        <p className='profile-p'>
           At JCR Healthcare, we understand that there is no better place than home. Our domiciliary care and home visits can help your older loved one retain independence and stay in the comfort of their home.
          </p>
      </div>
      </div>
      <div className='b-container'>
      <Box3/>
        <BoxAbout image={image2} alt='image2'/>
      </div>
        </div>
    );
}
 
export default About;