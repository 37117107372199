import React from 'react';

function Box1 (props) {
    return (  
        <div className='s-box'>
            <div className='s-b-text'>
            <div className='s-b-img'>
                <img src={props.image} alt={props.alt}></img>
            </div>
            
            <h1 className='box-header'>Domicilliary staff</h1>
                <p>We have qualified compassionate and CRB checked Care Professionals that help to deliver high standard care.</p>
            </div>

        </div>
    );
}
 
export default Box1;